import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton
} from 'video-react';

import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';

import './setup.css';

import brassDeflectorImage11 from '../images/learn-11-512.png';
import brassDeflectorImage12 from '../images/learn-12-512.png';
import brassDeflectorImage21 from '../images/learn-21-512.png';
import brassDeflectorImage22 from '../images/learn-22-512.png';
import brassDeflectorImage31 from '../images/learn-31-512.png';
import brassDeflectorImage32 from '../images/learn-32-512.png';

import posterImage from '../images/poster-image.jpg';

const Setup = () => (
  <Layout>
    <SEO title="Learn" />
    <Header />
    {/*<div className="container__ket-blue learn-title-bar">LEARN</div>*/}
    <div className="container__ket">
      <Grid className="product-video learn-video" stackable>
        <Grid.Row centered>
          <Grid.Column width="8" textAlign="center">
            <div className="learn-video-title">Simple Setup</div>
            <Player poster={posterImage}>
              <BigPlayButton position="center" />
              <source src="../videos/learn-how-to-demo.mp4" />
              <ControlBar>
                <VolumeMenuButton />
                <CurrentTimeDisplay disabled />
                <TimeDivider disabled />
                <PlaybackRateMenuButton disabled />
              </ControlBar>
            </Player>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    <div className="container__ket-blue">
      <Grid stackable padded>
        <Grid.Row centered>
          <Grid.Column width="4" textAlign="center">
            <Image alt="Brass Clip PR21 Colors" src={brassDeflectorImage11} />
            <div className="learn-image-caption">Locate tongue, note two sides</div>
          </Grid.Column>
          <Grid.Column width="4" textAlign="center">
            <Image alt="Brass Clip SC30 Colors" src={brassDeflectorImage12} />
            <div className="learn-image-caption">
              Insert Brass-Clip into the Clip Side
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width="4" textAlign="center">
            <Image alt="Brass Clip PR21 Colors" src={brassDeflectorImage21} />
            <div className="learn-image-caption">
              Insert plastic stiffener into the Stiffener Side
            </div>
          </Grid.Column>
          <Grid.Column width="4" textAlign="center">
            <Image alt="Brass Clip SC30 Colors" src={brassDeflectorImage22} />
            <div className="learn-image-caption">Snap onto scope</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width="4" textAlign="center">
            <Image alt="Brass Clip PR21 Colors" src={brassDeflectorImage31} />
            <div className="learn-image-caption">Snap onto Picatinny rail</div>
          </Grid.Column>
          <Grid.Column width="4" textAlign="center">
            <Image alt="Brass Clip PR21 Colors" src={brassDeflectorImage32} />
            <div className="learn-image-caption">Attach your Brass-Deflector</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </Layout>
);

export default Setup;
